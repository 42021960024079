import React from "react"
import styled from "styled-components"

import { AboutCard } from "../elements"
import { HeadingPrimary } from "../typography"
import {
  consultanceyGuide,
  costEffective,
  customisedApproach,
  disasterRecovery,
  industryLeader,
  ongoingManagement,
  premiumProduct,
  reliableService,
} from "../../images/whychooseus"

const choose = [
  {
    id: 1,
    src: industryLeader,
    title: "Industry Leaders",
    content:
      "With 15 years of industry experience behind us, you can trust us to fulfil our promises. We have worked with a catalogue of satisfied customers in the past and look forward to fostering new connections with organisations of all sizes.",
    order: 0,
  },
  {
    id: 2,
    src: reliableService,
    title: "Reliable Service",
    content:
      "The service we provide is always efficient, customised, and above all, reliable. We hold ourselves accountable for every job we take on and strive to deliver premium results every time. You can trust that we’ll be there when you need us.",
    order: 1,
  },
  {
    id: 3,
    src: customisedApproach,
    title: "Customised Approach",
    content:
      "We understand that every business is different. That’s why we tailor our approach to the individual needs of your business, rather than recycling the same old methods for everyone. IT solutions should never be a one-size-fits-all fix, and ours never are.",
    order: 0,
  },
  {
    id: 4,
    src: premiumProduct,
    title: "Premium Products and Partners",
    content:
      "A huge part of our success comes down to working only with effective products and trust-worthy partners. This way, we can provide the most efficient IT solutions that will help your business to flourish.",
    order: 1,
  },
  {
    id: 5,
    src: consultanceyGuide,
    title: "Consultancy and Guidance",
    content:
      "We put our knowledge and expertise to good use by providing ongoing consultancy and guidance to our clients. We’ll give you all the information you need to make the best decision for your business and then walk you through every process so we’re always on the same page.",
    order: 0,
  },
  {
    id: 6,
    src: costEffective,
    title: "Cost-effective Solutions",
    content:
      "Our IT solutions will make all the difference to your business but they won’t break the bank. We’ll show you how you can save money by automating processes and updating your systems, all in a cost-effective fashion.",
    order: 1,
  },
  {
    id: 7,
    src: ongoingManagement,
    title: "Ongoing Management",
    content:
      "Once we’ve provided your business with one or more of our IT services, we also offer continuing management. Our team is always available to run and control platforms, Cloud services, software, and more so your team can focus on your business.",
    order: 0,
  },
  {
    id: 8,
    src: disasterRecovery,
    title: "Disaster Recovery",
    content:
      "IT doesn’t always run smoothly, so we make sure that when disaster does strike, you’re well-protected. Along with cyber security, we specialise in backing up data and business continuity techniques to help minimise damage and get your business back on your feet after any mishap.",
    order: 1,
  },
]

export function WhyChooseUs() {
  return (
    <Cover>
      <HeadingPrimary>Why choose us?</HeadingPrimary>
      <Wrapper>
        {choose.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: 3rem 0;
  text-align: center;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  margin: 2.5rem 0 0 0;
`
