import React from "react"
import styled from "styled-components"
import {
  IndustryLeader,
  OurPassion,
  PeaceMind,
} from "../../svgs/aboutus/aboutUsIcons"
import { AboutCard } from "../elements"
import { HeadingPrimary } from "../typography"

export function WhoWeAre() {
  return (
    <Cover>
      <HeadingPrimary>Who we are?</HeadingPrimary>
      <Wrapper>
        <AboutCard
          content=" ICThub is an ICT industry leader with more than 15 years of
            experience. Our qualified and reliable team members are experts in
            telecommunications and managed IT services, specialising in applying
            premium IT and cyber security strategies and products to your
            business model."
        >
          <IndustryLeader />
        </AboutCard>
        <AboutCard
          order="1"
          content="Our passion lies in connecting with businesses of all sizes and implementing cost-effective strategies to drive productivity and growth. We’re proud to say that the organisations we’ve worked with in the past have experienced increased efficiency, improved customer satisfaction, and unprecedented development."
        >
          <OurPassion />
        </AboutCard>
        <AboutCard content="When you work with us, you have peace of mind that we have the digital aspects of your company, from voice and mobility to cyber security, under control. Leave it to us so you can focus on running your business.">
          <PeaceMind />
        </AboutCard>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: 3rem 0;
  text-align: center;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  margin: 2.5rem 0 0 0;
`
