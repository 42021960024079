import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { CatchyTitle } from "../typography/CatchyTitle"
import { HeaderTitle } from "../typography/HeaderTitle"
import { Button, LightButton } from "../elements/Button"

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        index: file(relativePath: { eq: "headers/mantalking.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = [data.index.childImageSharp.fluid].reverse()
      // const imageData = data.index.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          // backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export function MainHeader() {
  return (
    <ImageCover>
      <Overlay>
        <DetailCover>
          <CatchyTitle>Complete IT Solutions</CatchyTitle>
          <HeaderTitle>
            Invest in IT,
            <br /> Invest in your business
          </HeaderTitle>
          <ButtonWrapper>
            <Button to="/contact-us">Contact us</Button>
          </ButtonWrapper>
        </DetailCover>
      </Overlay>
    </ImageCover>
  )
}

const ImageCover = styled(BackgroundSection)`
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(
    68.8deg,
    rgba(223, 0, 255, 0.44) 0.98%,
    rgba(0, 225, 255, 0.44) 99.43%
  ); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    68.8deg,
    rgba(223, 0, 255, 0.44) 0.98%,
    rgba(0, 225, 255, 0.44) 99.43%
  ); */
  background-color: ${props => props.theme.filter.overlay};
  display: flex;
  align-items: center;
`

const DetailCover = styled.div`
  position: relative;
  padding: 0 0 0 15vw;

  @media only screen and (max-width: 75em) {
    padding: 0 0 0 15vw;
  }
  @media only screen and (max-width: 56.25em) {
    padding: 0 0 0 15vw;
  }
  @media only screen and (max-width: 37.5em) {
    padding: 0 0.5vw 0 2.5vw;
  }
  @media only screen and (min-width: 112.5em) {
    padding: 0 0 0 15vw;
  }
`

const ButtonWrapper = styled.div`
  padding: 2rem 0;
`
