import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { LightButton } from "../elements"
import { CatchyTitle } from "../typography/"

export function PageHeader({ fluid, title, catchy, subtitle }) {
  return (
    <>
      <Cover>
        <StyledBackground fluid={fluid}>
          <ContentCover>
            <CatchyTitle>{catchy}</CatchyTitle>
            <PageTitle>{title}</PageTitle>
            <SubTitle>{subtitle}</SubTitle>
          </ContentCover>
          <LightButton
            borderColor={props => props.theme.color.highlight}
            to="/contact-us/"
          >
            Contact us
          </LightButton>
        </StyledBackground>
      </Cover>
    </>
  )
}

const Cover = styled.section`
  width: 100%;
  height: 100vh;
`

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 10vw;
  @media only screen and (max-width: 75em) {
    padding: 0 0 0 10vw;
  }
  @media only screen and (max-width: 56.25em) {
    padding: 0 0 0 10vw;
  }
  @media only screen and (max-width: 37.5em) {
    padding: 0 0 0 3vw;
  }
  @media only screen and (min-width: 112.5em) {
    padding: 0 0 0 15vw;
  }
`

const ContentCover = styled.div`
  text-align: left;
  padding: 0 0 1rem 0;
  width: 68%;
  @media only screen and (max-width: 75em) {
    width: 90%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 90%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 50%;
  }
`

const PageTitle = styled.h1`
  text-transform: capitalize;
  font-weight: 800;
  font-size: 5rem;
  letter-spacing: 0.4rem;
  line-height: 135%;
  padding: 1rem 0;
  color: ${props => props.color || props.theme.color.white};
  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 75em) {
    font-size: 5rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 5rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 4.5rem;
    letter-spacing: 0.2rem;
  }
`
const SubTitle = styled.h3`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  line-height: 180%;
  color: ${props => props.color || props.theme.color.white};
  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 75em) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
`
