import React from "react"
import styled from "styled-components"
import pages from "../../theme/pages"
import { ProductCard } from "../elements"
import { HeadingPrimary } from "../typography"

import {
  cloudServices,
  collabration,
  cyberSecurity,
  digitalWorkplace,
  internet,
  itProcurement,
  managedItServices,
  managedNetwork,
  voiceandmobility,
} from "../../images/cardicons"

const services = [
  {
    id: 1,
    src: managedItServices,
    title: "Managed IT Services",
    content:
      "In the digital age, managed IT services make your business a lot easier to run.",
    to: pages.managedItServices,
  },
  {
    id: 2,
    src: cyberSecurity,
    title: "Cyber Security",
    content:
      "No business can afford to be careless when it comes to IT security. Our premium technology and proven strategies will keep you protected.",
    to: pages.cyberSecurity,
  },
  {
    id: 3,
    src: cloudServices,
    title: "Cloud Services",
    content:
      "Moving your business to a Cloud-based system has several advantages, ranging from real-time analytics to data collection to increased cyber security.",
    to: pages.cloudServices,
  },
  {
    id: 4,
    src: managedNetwork,
    title: "Managed Network",
    content:
      "Managed network services are immensely beneficial to a business, especially now that so many organisations rely so heavily on technology.",
    to: pages.managedNetwork,
  },
  {
    id: 5,
    src: digitalWorkplace,
    title: "Digital Workplace",
    content:
      "Having an effective digital workplace behind you will help your business to succeed in a world shaped by physical and geographical restrictions.",
    to: pages.digitalWorkplace,
  },
  {
    id: 6,
    src: itProcurement,
    title: "IT Procurement",
    content:
      "The right IT resources can be the difference between a company that fails and a company that flourishes.",
    to: pages.itProcurement,
  },
  {
    id: 7,
    src: voiceandmobility,
    title: "Voice and Mobility",
    content:
      "Our voice and mobility solutions make it easier than ever for your business to connect and collaborate, both internally and externally. ",
    to: pages.voiceMobility,
  },
  {
    id: 8,
    src: collabration,
    title: "Collabration",
    content:
      "It’s crucial that your people are able to interact and collaborate with ease, regardless of where they’re located.",
    to: pages.collabration,
  },
  {
    id: 9,
    src: internet,
    title: "Internet & Data",
    content:
      "In today’s world, internet connection is a big deal. Without a reliable internet connection.",
    to: pages.internetData,
  },
]

export function Services() {
  return (
    <Cover>
      <HeadingPrimary>Product & Services</HeadingPrimary>
      <Wrapper>
        {services.map((item, id) => (
          <ProductCard
            key={item.id}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: 5rem 0;
  text-align: center;
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  width: 80%;
  margin: 2.5rem auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media only screen and (max-width: 75em) {
    width: 80%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 80%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 60%;
  }
`
